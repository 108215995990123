import React from 'react';
import CharacterGrid from './components/CharacterGrid.js';
import Header from './components/Header.js';
import HomeBanner from './components/HomeBanner.js';
import './App.css'
import Footer from './components/Footer.js';
import NinjaToolsTable from './components/NinjaToolsModal.js';

function App() {
  return (
    <div className="App">
      <Header />
      <HomeBanner />
      <div style={{ paddingTop: '0px' }}> {/* Ajustez paddingTop pour éviter que le contenu soit caché par la bannière */}
        </div>
      <CharacterGrid />
      <Footer />
    </div>
  );
}

export default App;
import React, { useEffect } from 'react';
import '../css/HomeBanner.css'; // Assurez-vous que ce fichier CSS est importé

const createRaindrops = () => {
  const raindropContainer = document.querySelector('.rain');
  const numberOfRaindrops = 500; // Augmentez le nombre de gouttes de pluie

  for (let i = 0; i < numberOfRaindrops; i++) {
    const raindrop = document.createElement('div');
    raindrop.classList.add('raindrop');
    raindrop.style.left = `${Math.random() * 100}%`;
    raindrop.style.animationDelay = `${Math.random() * 1}s`; // Délais aléatoires pour les animations
    raindropContainer.appendChild(raindrop);
  }
};

const createLightning = () => {
  const homeBanner = document.querySelector('.home-banner');

  const lightning = document.createElement('div');
  lightning.classList.add('lightning');
  lightning.style.left = `${Math.random() * 100}%`;

  homeBanner.appendChild(lightning);

  setTimeout(() => {
    homeBanner.removeChild(lightning);
  }, 500); // Durée des éclairs augmentée
};

const HomeBanner = () => {
  useEffect(() => {
    createRaindrops();

    const lightningInterval = setInterval(() => {
      createLightning();
      createLightning(); // Ajout d'un deuxième éclair pour plus de fréquence
    }, Math.random() * 2000 + 1000); // Intervalle aléatoire pour les éclairs

    return () => {
      clearInterval(lightningInterval);
    };
  }, []);

  return (
    <div className="home-banner">
      <div className="character-container">
        <img src="../naruto_sasuke.png" alt="Naruto and Sasuke" className="character-banner" />
        <div className="rain"></div>
      </div>
    </div>
  );
};

export default HomeBanner;
import React, { useEffect, useState } from "react";
import axios from "axios";
import "../css/NinjaToolsModal.css"; // Assurez-vous de créer et de lier ce fichier CSS

const NinjaToolsModal = () => {
  const [tools, setTools] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Effectue la requête pour obtenir les données
    axios
      .get("https://narutostormtools.com/api/tools.php")
      .then((response) => {
        setTools(response.data);
      })
      .catch((error) => {
        console.error(
          "Il y a eu une erreur lors de la récupération des données :",
          error
        );
      });
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === "ntm-modal-background") {
      toggleModal();
    }
  };

  return (
    <div>
      <button onClick={toggleModal} className="ntm-open-modal-button">
        Show All NinjaTools
      </button>

      {isModalOpen && (
        <div
          id="ntm-modal-background"
          className={isModalOpen ? "ntm-open" : ""}
          onClick={handleOutsideClick}
        >
          <div className={`ntm-modal-content ${isModalOpen ? "ntm-open" : ""}`}>
            <button onClick={toggleModal} className="ntm-close-modal-button">
              Close
            </button>
            <table className="ntm-tools-table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Logo</th>
                  <th>Name</th>
                  <th>Effect</th>
                  <th>Cooldown</th>
                </tr>
              </thead>
              <tbody>
                {tools.map((tool) => {
                  let cooldownStr = "";
                  if (parseInt(tool.id) >= 1 && parseInt(tool.id) <= 15) {
                    const cooldownValue =
                      100 / (0.05 * parseFloat(tool.cooldown)) / 30;
                    cooldownStr = `${cooldownValue.toFixed(2)} seconds`;
                  }
                  return (
                    <tr key={tool.id}>
                      <td>{tool.id}</td>
                      <td>
                        <img
                          src={`/asset/ninjatools/${tool.id}.png`}
                          alt={`Tool ${tool.id}`}
                        />
                      </td>
                      <td>{tool.name}</td>
                      <td>{tool.effect}</td>
                      <td>{cooldownStr}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default NinjaToolsModal;

import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "./Modal.js";
import SearchBar from "./SearchBar.js";
import FilterMenu from "./FilterMenu.js";
import "../css/CharacterGrid.css";
import NinjaToolsModal from "./NinjaToolsModal.js";

const CharacterGrid = () => {
  const [characters, setCharacters] = useState([]);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTools, setSelectedTools] = useState([]);

  useEffect(() => {
    axios
      .get("https://narutostormtools.com/api/characters.php")
      .then((response) => {
        setCharacters(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the characters!", error);
      });
  }, []);

  const handleCharacterClick = (character) => {
    setSelectedCharacter(character);
  };

  const handleCloseModal = () => {
    setSelectedCharacter(null);
  };

  const handleToolChange = (toolId, value) => {
    setSelectedTools((prevTools) => {
      let updatedTools = [...prevTools];

      if (value && value !== "Select a Tool") {
        updatedTools[toolId - 1] = value;
      } else {
        updatedTools[toolId - 1] = undefined;
      }

      // Check if all tools are now undefined (empty)
      const allToolsEmpty = updatedTools.every((tool) => tool === undefined);

      // If all tools are empty, return an empty array to show all characters
      return allToolsEmpty ? [] : updatedTools;
    });
  };

  const isCharacterHighlighted = (character) => {
    const isMatch = character.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    // Create an array of the character's tools
    const characterTools = [
      character.tool1_id,
      character.tool2_id,
      character.tool3_id,
      character.tool4_id,
      character.tool5_id,
      character.tool6_id,
      character.tool7_id,
      character.tool8_id,
    ];

    // Filter out undefined values from selectedTools
    const filteredSelectedTools = selectedTools.filter(
      (tool) => tool !== undefined
    );

    // Check if all filtered selected tools are present among the character's tools
    const hasToolMatch =
      filteredSelectedTools.length === 0 ||
      filteredSelectedTools.every((selectedTool) =>
        characterTools.includes(selectedTool)
      );

    return isMatch && hasToolMatch;
  };

  const renderGrid = (startId, endId) => (
    <div className="grid-container">
      <div className="character-grid">
        {characters
          .filter(
            (character) => character.id >= startId && character.id <= endId
          )
          .map((character) => {
            const isHighlighted = isCharacterHighlighted(character);
            return (
              <div
                key={character.id}
                className="character-item"
                onClick={() => handleCharacterClick(character)}
                style={{
                  opacity: isHighlighted ? "1" : "0.3",
                  filter: isHighlighted ? "none" : "grayscale(100%)",
                }}
              >
                <img
                  src={`/asset/characters/${character.id}.png`}
                  alt={character.name}
                />
              </div>
            );
          })}
      </div>
    </div>
  );

  return (
    <div className="CHGridAll">
      <div className="SearchAndFilter">
        <SearchBar searchTerm={searchTerm} onSearchChange={setSearchTerm} />
        <div className="NinjaToolsButton">
          <NinjaToolsModal />
        </div>
        <div className="filters">
          <FilterMenu
            className="filtermenu1"
            toolId={1}
            placeholder="Item 1"
            onToolChange={handleToolChange}
          />
          <FilterMenu
            className="filtermenu2"
            toolId={2}
            placeholder="Item 2"
            onToolChange={handleToolChange}
          />
          <FilterMenu
            className="filtermenu3"
            toolId={4}
            placeholder="Item 3"
            onToolChange={handleToolChange}
          />
          <FilterMenu
            className="filtermenu4"
            toolId={3}
            placeholder="Item 4"
            onToolChange={handleToolChange}
          />
        </div>
      </div>
      <div className="containerdecontainer">
        <div className="main-container">
          {renderGrid(1, 24)}
          {renderGrid(25, 45)}
          {renderGrid(46, 62)}
          {renderGrid(63, 86)}
          {renderGrid(87, 107)}
        </div>
        <div className="main-container">
          {renderGrid(108, 125)}
          {renderGrid(126, 147)}
          {renderGrid(148, 157)}
          {renderGrid(158, Infinity)}
        </div>
      </div>
      <Modal character={selectedCharacter} onClose={handleCloseModal} />
    </div>
  );
};

export default CharacterGrid;

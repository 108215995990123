import React from 'react';
import '../css/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-donate">
          <p>If you would like to support the project financially:</p>
          <form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="hosted_button_id" value="3AEFBDYZYSLK2" />
            <input type="image" src="https://pics.paypal.com/00/s/NjFhZTA2OWUtMDM2ZC00NjVhLTlmZDYtZDFiMWE2YmI0NWU4/file.JPG" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" className="donate-button" />
            <img alt="" border="0" src="https://www.paypal.com/en_BE/i/scr/pixel.gif" width="1" height="1" />
          </form>
        </div>
        <div className="footer-discord">
          <p className="follow-text">Join Discord Partner</p>
          <a href="https://discord.gg/ultimateninja" target="_blank" rel="noopener noreferrer">
            <img src="./UltimateNinja.png" alt="Ultimate Nina" className="footer-logo" />
          </a>
        </div>
        <div className="footer-links">
          <p className="follow-text">Follow me on X</p>
          <a href="https://x.com/Yeo_DBGT" target="_blank" rel="noopener noreferrer">
            <img src="./x.png" alt="Yeo 𒉭 (@Yeo_DBGT) on X" className="footer-logo" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
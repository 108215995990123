import React from 'react';
import '../css/SearchBar.css'

const SearchBar = ({ searchTerm, onSearchChange }) => {
  return (
    <div id="search-container">
      <input 
        type="text" 
        id="search-bar" 
        placeholder="Search Character" 
        value={searchTerm}
        onChange={event => onSearchChange(event.target.value)}
      />
    </div>
  );
};

export default SearchBar;
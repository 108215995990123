import React from 'react';
import '../App.css'; // Assurez-vous que ce fichier CSS est importé

const Header = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <header className="header">
      <div className="logo-container">
        <img 
          src="./logo1.png" 
          alt="Logo" 
          className="logo" 
          onClick={scrollToTop} 
          style={{ cursor: 'pointer' }} // Change le curseur en pointeur pour indiquer que c'est cliquable
        />
      </div>
    </header>
  );
};

export default Header;
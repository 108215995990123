import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import '../css/FilterMenu.css'; // Assurez-vous d'avoir le fichier CSS approprié

const FilterMenu = ({ toolId, onToolChange, className, placeholder }) => {
  const [tools, setTools] = useState([]);
  const [error, setError] = useState(null); // Ajout d'un état pour la gestion des erreurs

  useEffect(() => {
    axios.get('https://narutostormtools.com/api/tools.php')
      .then(response => {
        setTools(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the tools!', error);
        setError('Erreur lors de la récupération des outils.');
      });
  }, []);

  const handleChange = (selectedOption) => {
    onToolChange(toolId, selectedOption ? selectedOption.value : null);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '40px',
      marginBottom: '10px',
      width: '140px', // Définissez la largeur fixe ici
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Couleur de fond noire avec transparence
      borderColor: 'rgba(68, 68, 68, 0.7)', // Couleur de la bordure avec transparence
      color: '#fff', // Couleur du texte
    }),
    menu: (provided) => ({
      ...provided,
      width: '140px', // Assurez-vous que le menu déroulant a la même largeur
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Couleur de fond noire avec transparence
      color: '#fff', // Couleur du texte
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5px',
      backgroundColor: state.isSelected ? 'rgba(51, 51, 51, 0.7)' : 'rgba(0, 0, 0, 0.7)', // Couleur de fond des options avec transparence
      color: '#fff', // Couleur du texte des options
      ':hover': {
        backgroundColor: 'rgba(68, 68, 68, 0.7)', // Couleur de surbrillance au survol avec transparence
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: '#fff', // Couleur du texte de la valeur sélectionnée
    }),
    menuList: (provided) => ({
      ...provided,
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
      gap: '10px',
      padding: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Couleur de fond de la liste avec transparence
      color: '#fff', // Couleur du texte de la liste
    }),
  };

  const formatOptionLabel = ({ label, value }) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <img
        src={`/asset/ninjatools/${value}.png`}
        alt={label}
        style={{ width: '50px', height: '50px' }}
      />
      <span style={{ marginLeft: '10px', color: '#fff' }}>{label}</span> {/* Couleur du texte */}
    </div>
  );

  const options = tools.map(tool => ({
    value: tool.id,
  }));

  return (
    <div className={className}>
      {error && <p className="error-message" style={{ color: '#ff4d4d' }}>{error}</p>} {/* Affichage du message d'erreur */}
      <Select
        styles={customStyles}
        options={options}
        onChange={handleChange}
        formatOptionLabel={formatOptionLabel}
        placeholder={placeholder} // Utilisez le prop placeholder ici
        isClearable
        isSearchable={false} // Désactiver la recherche
      />
    </div>
  );
};

export default FilterMenu;
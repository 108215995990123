import React, { useState, useEffect } from "react";
import "../css/Modal.css";

const Modal = ({ character, onClose }) => {
  const [fade, setFade] = useState(false);

  useEffect(() => {
    if (character) {
      setFade(false);
    }
  }, [character]);

  useEffect(() => {
    if (character) {
      console.log(character); // Ajoutez cette ligne pour vérifier les données
      setFade(false);
    }
  }, [character]);

  const handleClose = () => {
    setFade(true);
    setTimeout(() => {
      onClose();
    }, 300); // Correspond à la durée de la transition CSS
  };

  const handleOutsideClick = (e) => {
    if (e.target.className.includes("modal")) {
      handleClose();
    }
  };

  if (!character) return null;

  const isSpecialCharacter =
    character.name === "Kaguya Otsutsuki" || character.name === "Tayuya";

  return (
    <div
      className={`modal ${fade ? "fade-out" : ""}`}
      onClick={handleOutsideClick}
    >
      <div className="modal-content">
        <span className="close" onClick={handleClose}>
          &times;
        </span>
        <div className="characterFullName">
          {isSpecialCharacter && (
            <p className="special">
              *This character has two tools, because if you select him in the
              main game and switch, you'll get these tools
            </p>
          )}
          <h2 className="character-name">{character.name}</h2>
          <h3 className="undername">{character.undername}</h3>
        </div>
        <div className="character-tools-container">
          <div className="character">
            <img
              src={`/asset/characters/${character.id}.png`}
              alt={character.name}
              className="character-image"
            />
          </div>
          <div className="tools">
            {[1, 2, 3, 4].map((i) => (
              <div
                key={i}
                className={`tool-item tool-${
                  ["top", "right", "bottom", "left"][i - 1]
                }`}
              >
                <img
                  src={`/asset/ninjatools/${character[`tool${i}_id`]}.png`}
                  alt={character[`tool${i}_name`]}
                />
                <div className="tool-tip">
                  <strong>{character[`tool${i}_name`]}</strong>
                  <p>{character[`tool${i}_effect`]}</p>
                  {character[`tool${i}_id`] &&
                    parseInt(character[`tool${i}_id`]) >= 1 &&
                    parseInt(character[`tool${i}_id`]) <= 15 && (
                      <p>
                        {(() => {
                          const cooldownValue =
                            100 /
                            (0.05 *
                              parseFloat(character[`tool${i}_cooldown`])) /
                            30;
                          return `Cooldown: ${cooldownValue.toFixed(
                            2
                          )} seconds`;
                        })()}
                      </p>
                    )}
                </div>
              </div>
            ))}
          </div>
          {isSpecialCharacter && (
            <div>
              <div className="tools">
                {[5, 6, 7, 8].map((i) => (
                  <div
                    key={i}
                    className={`tool-item tool-${
                      ["top", "right", "bottom", "left"][i - 5]
                    }`}
                  >
                    <img
                      src={`/asset/ninjatools/${character[`tool${i}_id`]}.png`}
                      alt={character[`tool${i}_name`]}
                    />
                    <div className="tool-tip">
                      <strong>{character[`tool${i}_name`]}</strong>
                      <p>{character[`tool${i}_effect`]}</p>
                      {character[`tool${i}_id`] &&
                        parseInt(character[`tool${i}_id`]) >= 1 &&
                        parseInt(character[`tool${i}_id`]) <= 15 && (
                          <p>
                            {(() => {
                              const cooldownValue =
                                100 /
                                (0.05 *
                                  parseFloat(character[`tool${i}_cooldown`])) /
                                30;
                              return `Cooldown: ${cooldownValue.toFixed(
                                2
                              )} seconds`;
                            })()}
                          </p>
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
